<template>
  <section id="dashboard-home">
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
        cols="12"
      >
        <mlf-media-types />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AlertTypesList from '@mlfdataverse/media-types.vue'

export default {
  components: {
    BRow,
    BCol,
    'mlf-media-types': AlertTypesList,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    // this.$http.get('/analytics/data')
    //   .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
